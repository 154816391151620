<template>
    <v-container>
        <v-data-table
            :headers="headers"
            :items="paises"
            sort-by="desc"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Países</v-toolbar-title>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                    >
                        <v-container>
                            <v-card>
                                <v-card-title>
                                    Modificar País
                                </v-card-title>
                                <validation-observer
                                    ref="observer"
                                    v-slot="{ invalid }"
                                >
                                    <v-form @submit.prevent="submit" autocomplete="off">
                                        <v-card-text>
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="País"
                                            rules="required|min:4|max:50"
                                        >
                                            <v-text-field
                                            v-model="editedItem.txtDesc"
                                            :counter="50"
                                            :error-messages="errors"
                                            label="País"
                                            required
                                            clearable
                                            autofocus
                                            autocomplete="off"
                                            ></v-text-field>
                                        </validation-provider>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-row>
                                                <v-col>
                                                    <v-btn
                                                        class="mr-4"
                                                        type="submit"
                                                        block
                                                        plain
                                                        :disabled="invalid"
                                                    >
                                                        Guardar
                                                    </v-btn>
                                                </v-col>
                                                <v-col>
                                                    <v-btn
                                                        block 
                                                        plain 
                                                        @click="close"
                                                    >
                                                        Cancelar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>
                                    </v-form>
                                </validation-observer>
                            </v-card>
                        </v-container>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="80%">
                        <v-container>
                            <v-card>
                                <v-card-title class="h6">¿Eliminar el país {{ editedItem.txtDesc }}?</v-card-title>
                                <v-card-actions>
                                    <v-row>
                                        <v-col>
                                            <v-btn
                                                block 
                                                plain 
                                                @click="closeDelete"
                                            >
                                                No
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn
                                                class="mr-4"
                                                @click="deleteItemConfirm"
                                                block
                                                plain
                                            >
                                                Si
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-container>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn
                block
                plain
                @click="initialize"
            >
                Recargar
            </v-btn>
            </template>
        </v-data-table>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="snackbar_color"
            >
            <p class=" text-center">
                {{ text_snackbar }}
            </p>
        </v-snackbar>
    </v-container>
</template>

<script>
  import { required, max, min } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import axios from "axios";
  
  setInteractionMode('aggressive')

    extend('required', {
    ...required,
    message: '{_field_} no puede quedar vacio',
    })

    extend('max', {
    ...max,
    message: '{_field_} no puede tener mas de {length} caractéres',
    })

    extend('min', {
    ...min,
    message: '{_field_} no puede tener menos de {length} caractéres',
    })

  export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data: () => ({
      snackbar: false,
      text_snackbar: '',
      timeout: 3000,
      snackbar_color: '',
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Pais',
          align: 'start',
          sortable: true,
          value: 'txtDesc',
        },
        { text: 'Acciones', value: 'actions', align: 'end',  sortable: false },
      ],
      paises: [],
      editedIndex: -1,
      editedItem: {
        id: null,
        txtDesc: ''
      },
      defaultItem: {
        id: null,
        txtDesc: ''
      },
    }),

    computed: {

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        var response = await axios({url: process.env.VUE_APP_API + "parametrizacion/Paises",  method: "GET", headers:{ 'Authorization': 'Bearer '+this.$session.get('jwt')}});
        this.paises = response.data.data;
      },

      editItem (item) {
        this.editedIndex = this.paises.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.paises.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        try {    
            var response = await axios({url: process.env.VUE_APP_API + "parametrizacion/pais",  method: "DELETE", headers: { "Accept": "application/json", "Content-Type": "application/json;charset=UTF-8", 'Authorization': 'Bearer '+this.$session.get('jwt')}, data: { "Id": this.editedItem.id }});
             if(response.data.estado == "OK"){
                this.snackbar_color = 'success';
                this.text_snackbar = "Se elimino el país " + this.paises[this.editedIndex].txtDesc.toUpperCase() + ".";
                this.snackbar = true;
                this.paises.splice(this.editedIndex, 1)
                this.closeDelete()
            }else{
                this.snackbar_color = 'warning';
                this.text_snackbar = response.data.data.error;
                this.snackbar = true;
            }
            
        } catch (error) {
            this.snackbar_color = 'danger';
            this.text_snackbar = "Ocurrio un error.";
            this.snackbar = true;
        }
      },

      close () {
        this.dialog = false
        this.$refs.observer.reset();
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async submit () {
        try {  
            if (this.editedIndex > -1) {
                if(this.$refs.observer.validate())
                {
                    var response = await axios({url: process.env.VUE_APP_API + "parametrizacion/pais",  method: "PUT", headers: { "Accept": "application/json", "Content-Type": "application/json;charset=UTF-8", 'Authorization': 'Bearer '+this.$session.get('jwt')}, data: { "Id": this.editedItem.id, "txtDesc": this.editedItem.txtDesc }});
                    if(response.data.estado == "OK"){
                        this.snackbar_color = 'success';
                        this.text_snackbar = "Se modifico el país " + this.paises[this.editedIndex].txtDesc.toUpperCase() + " por " + this.editedItem.txtDesc.toUpperCase() + ".";
                        this.snackbar = true;
                        Object.assign(this.paises[this.editedIndex], this.editedItem)
                    }else{
                        this.snackbar_color = 'warning';
                        this.text_snackbar = response.data.data.error;
                        this.snackbar = true;
                    }
                }
            } else {
            this.paises.push(this.editedItem)
            }
            this.close()
        } catch (error) {
            this.snackbar_color = 'danger';
            this.text_snackbar = error;
            this.snackbar = true;
        }
      },
    },
  }
</script>